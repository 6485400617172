@import '../../styles/base.scss';

.p2p-navi {
  height: calc(100vh - 75px);
  overflow: auto;
  width: 100%;
  max-width: 250px;
  padding: 0 25px;

  .navi-wrap {
    overflow: auto;
    height: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 25px;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 8px 15px;
      // padding-left: 55px;
      color: $color-text-primary;
      color: #4f4f4f;
      font-weight: 500;

      &.current {
        background: #ede9fb;
        border-radius: 6px;
        color: $color-text-primary;
      }

      &.alert span {
        position: relative;
        display: inline-block;
        padding-right: 25px;

        &::after {
          @keyframes pulse-red {
            0% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
            }

            70% {
              transform: scale(1);
              box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
            }

            100% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
            }
          }

          content: '';
          background: rgba(255, 82, 82, 1);
          box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
          animation: pulse-red 2s infinite;
          position: absolute;
          top: 50%;
          right: 0;
          width: 7px;
          height: 7px;
          margin: -2px 0 0 0;
          border-radius: 100%;
          background-color: $color-failed;
        }
      }

      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: 50%;
      //   left: 25px;
      //   width: 18px;
      //   height: 18px;
      //   margin: -9px 0 0 0;
      //   background: #b9b9b9;
      //   mask-size: contain;
      //   mask-repeat: no-repeat;
      //   mask-position: center;
      // }

      // &.current::before {
      //   background: #6932d4;
      // }
    }
  }

  @media (max-width: 768px) {
    white-space: nowrap;
    max-width: 100% !important;
    padding: 0 !important;
    height: auto !important;

    ul {
      li {
        display: inline-block;

        a.current {
          background: transparent !important;
        }
      }
    }
  }
}

.p2p-page-wrap {
  display: flex;

  .p2p-page-content {
    height: calc(100vh - 75px);
    overflow: auto;
    overflow-x: hidden;
    flex: 1;
    margin-left: 10px;
    padding: 15px 25px 50px 50px;
    padding-top: 35px;

    .type-tag {
      display: inline-block;
      vertical-align: top;
      height: 20px;
      line-height: 20px;
      font-size: 11px;
      background-color: #ede9fb;
      padding: 0 10px;
      border-radius: 22px;
    }

    .back-nav {
      display: inline-block;
      vertical-align: top;
      position: relative;
      padding-left: 28px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 15px;
        margin: -7.5px 0 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url(../../assets/arrow-left.svg);
      }
    }

    .page-content-inner {
      width: 100%;
      max-width: 100%;
    }

    .btn-default {
      width: 100%;
      max-width: 200px;
    }
  }

  @media (max-width: 768px) {
    display: block !important;

    .p2p-page-content {
      height: calc(100vh - 155px);
      padding-left: 25px !important;
      margin-left: 0 !important;
    }
  }
}

.p2p-transaction-info-dialog-popup {
  padding: 0 !important;

  .p2p-transaction-info-dialog-container {
    margin: 0 !important;
  }

  .head {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: stretch;

    .illus {
      margin-left: 25px;

      img {
        height: 24px;
      }
    }

    h4 {
      position: relative;
      padding: 0 25px 0 15px;
      margin: 0;

      &::before {
        display: none;
        content: ' ';
        position: absolute;
        top: 50%;
        left: 25px;
        width: 8px;
        height: 8px;
        margin: -4px 0 0 0;
        border-radius: 100%;
        vertical-align: middle;
      }

      &.success::before {
        background-color: $color-success;
      }
      &.failed::before {
        background-color: $color-failed;
      }
      &.pending {
        background-color: var(--bs-warning);
      }
      &.in_progress {
        background-color: var(--bs-primary-border-subtle);
      }
    }
  }

  .content {
    padding: 20px 25px 20px;
  }

  .buttons {
    display: flex;
    padding: 0 25px 30px;
  }

  .info-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    .info-title {
      font-weight: bold;
    }

    .info-content {
      a {
        color: #6932d4;
        border-bottom: 1px dotted #6932d4;
      }

      &.success {
        color: $color-success;
        font-weight: 500;
      }
      &.failed {
        color: $color-failed;
        font-weight: 500;
      }
      &.pending {
        color: var(--bs-warning);
        font-weight: 500;
      }
      &.in_progress {
        color: var(--bs-primary-border-subtle);
        font-weight: 500;
      }
    }

    .accts-info {
      list-style: none;
      margin: 0;
      padding: 0;
      align-items: center;
      justify-content: space-between;

      span.wrap {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .right {
          text-align: right;
          font-weight: bold;
        }
      }
    }

    .left {
      width: 40%;
    }

    .right {
      max-width: 70%;
      text-align: right;
    }

    &.block {
      display: block;
      align-items: unset;
      justify-content: unset;

      .right {
        max-width: 100%;
        text-align: left;
      }
    }
  }
}
