.currency-activity-popup {
  padding: 0 !important;

  .currency-activity-chooser {
    padding: 45px 25px 15px;

    .head {
      display: flex;
      justify-content: stretch;
      align-items: center;

      h4 {
        padding: 0;
        margin: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
        border-bottom: 1px solid #e2e1e1;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
