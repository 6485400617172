@import '../../../styles/base.scss';

.table-dev-keys {
  white-space: nowrap;
  overflow: auto;

  table {
    min-width: 768px !important;

    tr {
      td {
        border-bottom: 0.75px solid rgba(0, 0, 0, 0.1);
      }

      &:last-child td {
        border-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 100% !important;

    table {
      min-width: 100% !important;

      td:not(.hidden-) {
        padding-left: 15px !important;
      }
      td:last-child {
        text-align: right;
      }
    }
  }
}

.table-filters-wrap {
  background-color: #ffffff;
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
  border-radius: 3px;
  padding: 15px 25px 20px;
}

.webhook-history-table {
  .table-wrapper {
    background-color: #ffffff;
    box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
    border-radius: 3px;
  }

  tbody tr {
    cursor: pointer;

    &:hover td {
      background-color: rgba(160, 163, 189, 0.15);
    }

    @media (max-width: 768px) {
      &:hover td {
        background-color: unset;
      }
    }
  }
}

.webhook-event-dialog-popup {
  padding: 0 !important;
  max-width: 800px !important;

  .webhook-event-dialog-container {
    margin: 0 !important;
  }

  .head {
    border-bottom: 2px solid #cecece;
    padding: 0 25px;
  }

  .webhook-event-dialog-info {
    .content {
      display: table;
      max-width: 700px;
    }

    .content-row {
      display: table-row;
      max-width: 800px;
    }

    .content-column {
      display: table-cell;
      padding: 15px 25px;
      min-width: 170px;
      border-bottom: 1px solid #cecece;
      max-width: 630px;
    }

    .content-row:last-child .content-column {
      border-bottom: 0;
    }

    .code-wrap {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      .content {
        display: block;
      }

      .content-row {
        display: block;

        .content-column:first-child {
          border-bottom: 0;
          padding-bottom: 5px;
        }

        .content-column:last-child {
          padding-top: 0;
        }
      }

      .content-column {
        display: block;
        max-width: 100%;
      }
    }
  }
}

.devtools-wrap {
  .devtools-nav {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      margin-right: 25px;
      cursor: pointer;
      font-weight: bold;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
