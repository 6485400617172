@import '../../../styles/base.scss';

.search-filter-config {
  .search-input {
    width: 100%;
    background: $color-white !important;
  }

  .filter-btn {
    color: #344054;
    font-weight: 500;
    background: $color-white !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid #d0d5dd;
    border-radius: 4px;

    img {
      display: inline-block;
      vertical-align: top;
      margin-top: 3px;
    }

    &:not(.mobile) {
      img {
        margin-right: 5px;
      }
    }
  }
}
