@import '../../styles/base.scss';

.addresses-dashboard {
  height: 100vh;
  overflow: auto;
}

.addresses-page-wrap {
  padding: 25px;
  padding-top: 0;

  .table-advanced {
    .table-wrapper {
      background-color: #ffffff;
      box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
      border-radius: 3px;
    }
  }
}

.addresses-table {
  white-space: nowrap;
  overflow: auto;

  table {
    min-width: 880px;

    td {
      cursor: pointer;

      &.is-placeholder {
        padding: 15px;
      }

      .custom-placeholder {
        min-width: 20px;
      }
    }

    @media (max-width: 768px) {
      min-width: 100%;

      th {
        display: none;
      }
    }
  }

  td .wrap {
    max-width: 90%;
  }

  .illus-wrap {
    display: flex;
    align-items: center;
    justify-content: stretch;

    .illus {
      margin-top: -5px;
      width: 45px;

      img {
        width: 20px;
        height: 20px;

        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
        }
      }

      .custom-placeholder {
        width: 20px;
        height: 20px;
      }
    }

    .info {
      @media (max-width: 768px) {
        width: calc(100vw - 100px);
      }

      .h {
        @media (max-width: 450px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        small {
          font-weight: normal;
        }
      }

      .addr {
        max-width: 100%;

        @media (max-width: 768px) {
          display: inline-block !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    background-color: transparent !important;
    box-shadow: none !important;

    td {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .order-details {
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left,
      .right {
        width: 100%;
      }

      .right {
        text-align: right;
      }
    }
  }
}
