@import '../../../styles/base.scss';

.settings-navi {
  height: calc(100vh - 75px);
  overflow: auto;
  width: 100%;
  max-width: 250px;
  padding: 0 25px;

  .navi-wrap {
    overflow: auto;
    height: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 25px;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 8px 15px;
      padding-left: 55px;
      color: $color-text-primary;
      color: #4f4f4f;
      font-weight: 500;

      &.current {
        background: #ede9fb;
        border-radius: 6px;
        color: $color-text-primary;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 25px;
        width: 18px;
        height: 18px;
        margin: -9px 0 0 0;
        background: #b9b9b9;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
      }

      &.current::before {
        background: #6932d4;
      }

      &.profile::before {
        mask: url(../../../assets/person.svg) 70%;
      }
      &.business::before {
        mask: url(../../../assets/city.svg) 70%;
      }
      &.password::before {
        mask: url(../../../assets/lock.svg) 70%;
      }
      &.security::before {
        mask: url(../../../assets/shield-add.svg) 70%;
      }
      &.accounts::before {
        mask: url(../../../assets/funds.svg) 70%;
      }
      &.addresses::before {
        mask: url(../../../assets/wallet.svg) 70%;
      }
      &.team::before {
        mask: url(../../../assets/peoples-two.svg) 70%;
      }
      &.compliance::before {
        mask: url(../../../assets/certificate.svg) 70%;
      }
      &.developers::before {
        mask: url(../../../assets/code.svg) 70%;
      }
      &.notifications::before {
        mask: url(../../../assets/notification.svg) 70%;
      }
      &.system::before {
        mask: url(../../../assets/data-server.svg) 70%;
      }
    }
  }

  @media (max-width: 768px) {
    white-space: nowrap;
    max-width: 100% !important;
    padding: 0 !important;
    height: auto !important;

    ul {
      li {
        display: inline-block;

        a.current {
          background: transparent !important;
        }
      }
    }
  }
}
