@import '../../styles/base.scss';

.v-cards-dashboard {
  height: 100vh;
  overflow: auto;
}

.v-cards-page-wrap {
  padding: 25px;
  padding-top: 0;
}

.table-wrapper-cards {
  .illus-wrap {
    display: flex;
    align-items: center;
    justify-content: stretch;

    .illus {
      margin-right: 15px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .info {
      width: 100%;
      line-height: 20px;
    }
  }

  .btns-cell {
    a {
      margin-right: 15px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100%;
      max-width: 100px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  tbody tr {
    cursor: pointer;

    td.is-placeholder {
      padding-left: 15px;
    }

    &:hover td {
      background-color: rgba(160, 163, 189, 0.15);
    }

    @media (max-width: 768px) {
      &:hover td {
        background-color: unset;
      }
    }
  }
}

.cards-dashboard {
  height: 100vh;
  overflow: auto;
}

.v-cards-page-wrap {
  .table-advanced {
    .table-wrapper {
      background-color: #ffffff;
      box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
      border-radius: 3px;

      .truncate-text {
        width: 100%;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media (max-width: 768px) {
    .table-wrapper {
      background-color: transparent !important;
      box-shadow: none !important;

      th {
        display: none;
      }

      td {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

.card-samp_wrap {
  padding: 0 15px;
}
.card-samp__part {
  position: relative;
  box-shadow: 1px 1px #aaa3a3;
  z-index: 1000;
  display: inline-block;
  width: 320px;
  height: 190px;
  background-image: linear-gradient(to right bottom, #5828ba, #6932d4);
  border-radius: 8px;
}
.card-samp__front {
  padding: 18px;
}
.card-samp__back {
  padding: 18px 0;
}
.card-samp__black-line {
  margin-top: 5px;
  height: 38px;
  background-color: #303030;
}
.card-samp__logo {
  color: #ffffff;
  font-size: 1.5em;
}
.card-samp__front-logo {
  position: absolute;
  top: 18px;
  right: 18px;
}
.card-samp__square {
  border-radius: 5px;
  height: 30px;
}
.card-samp_numer {
  cursor: pointer;
  display: block;
  width: 100%;
  word-spacing: 4px;
  font-size: 20px;
  letter-spacing: 2px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}
.card-samp__space-75 {
  cursor: pointer;
  width: 75%;
  float: left;

  .card-samp__info:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}
.card-samp__space-25 {
  cursor: pointer;
  width: 25%;
  float: left;

  .card-samp__info:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}
.card-samp__label {
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
}
.card-samp__info {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.card-samp__back-content {
  padding: 15px 15px 0;
}
.card-samp__secret--last {
  cursor: pointer;
  color: #303030;
  text-align: right;
  margin: 0;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}
.card-samp__secret {
  padding: 5px 12px;
  background-color: #fff;
  position: relative;
}
.card-samp__secret:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  height: calc(100% + 6px);
  width: calc(100% - 42px);
  border-radius: 4px;
  background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
}
.card-samp__back-logo {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.card-samp__back-square {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.modal-card-display .slick-dots {
  margin-top: 25px;
  position: relative;
  bottom: 0;

  li {
    width: auto;
    display: inline-block;
    vertical-align: top;

    a {
      display: block;
      color: #aaa3a3;
      border: 1px solid #aaa3a3;
      height: 30px;
      line-height: 28px;
      border-radius: 30px;
      width: 100px;

      &:hover {
        color: #6932d4;
      }
    }

    &.slick-active a {
      color: #6932d4;
      border: 1px solid #5828ba;
    }
  }
}

.card-txn-container {
  h4 {
    text-align: center;
    margin-bottom: 18px;
  }

  .search-container-wrap {
    display: flex;
    justify-content: center;
  }

  .search-container {
    width: 85%;
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: #ffffff;
    padding: 12px 16px;
    border: 1px solid #f0f0f0;
    border-radius: 30px;

    img {
      width: 20px;
      height: 20px;
    }

    input {
      border: none;
      width: 100%;
      outline: none;
    }
  }

  .card-txn-label {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    justify-items: center;
    gap: 50px;
    margin-top: 20px;

    span {
      cursor: pointer;
      position: relative;
      transition: color 0.3s;

      &:hover {
        color: #6932d4;
      }

      &.active-filter {
        color: #6932d4;

        &::after {
          content: '';
          display: block;
          height: 2px;
          background-color: #6932d4;
          position: absolute;
          bottom: -15px;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .card-txn-wrapper {
    margin-top: 20px;
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
  }

  .loading-wrap {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px dotted #6932d4;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .no-txn {
    max-width: 300px;
    max-height: 300px;
    text-align: center;
  }

  .no-txn img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px; /* Adjust the margin as needed */
    object-fit: contain;
  }

  .txn-status {
    display: flex;
    justify-content: center;
    justify-items: center;
    padding: 5px;
  }

  .card-txn-item {
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    padding: 15px 0;

    &:hover {
      background-color: rgba(160, 163, 189, 0.15);
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    .card-txn-title {
      display: flex;
      justify-content: space-between;
    }

    .txn-modal-lhs {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      p {
        margin: 0;
      }
    }
  }

  .txn-det-item {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }

  .txn-det-item p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .txn-det-item:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .det-item-lhs {
    color: #66687e;
    font-size: medium;
  }

  .det-item-rhs {
    color: #000000;
    font-weight: 500;
  }
}

ul.card-transactions {
  list-style: none;
  margin: 0;
  padding: 15px 0 0 0;
  cursor: pointer;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    padding-left: 50px;
    position: relative;
    border-bottom: 1px solid #e2e2e2;

    &:hover {
      background-color: rgba(160, 163, 189, 0.15);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 36px;
      height: 36px;
      margin: -18px 0 0 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .left {
      max-width: 60%;
    }
    .right {
      display: flex;
      align-items: baseline;
    }

    span.currency {
      font-size: 12px;
      color: #6c6c6c;
    }
    span.amount {
      font-size: 18px;
      margin-left: 5px;
      font-weight: 700;
      margin-right: 15px;
    }

    &.tx-in {
      &::before {
        background-image: url(../../assets/card-tx-in.svg);
      }

      .amount {
        color: #2b9348;
      }
    }
    &.tx-withdrawal-pending,
    &.tx-out {
      &::before {
        background-image: url(../../assets/card-tx-out.svg);
      }

      .amount {
        color: #e5383b;
      }
    }
    &.tx-pending {
      &::before {
        background-image: url(../../assets/card-txn-pending.svg);
      }

      .amount {
        color: #ffffff;
      }
    }
    &.tx-reverserd-refunded {
      &::before {
        background-image: url(../../assets/card-txn-reversed.svg);
      }

      .amount {
        color: #2b9348;
      }
    }
    &.tx-declined {
      &::before {
        background-image: url(../../assets/card-txn-declined.svg);
      }

      .amount {
        color: #000000;
      }
    }

    .label {
      font-weight: 500;
      color: #000000;
    }
    .value {
      color: #6c6c6c;
      font-size: small;
    }
    .info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: medium;
      color: #000000;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.card-txn-pagination {
  text-align: right;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-wrap {
    display: inline-block;
    margin: 0 auto;

    .item-total,
    .item-nav {
      position: relative;
      display: inline-block;
      vertical-align: top;
    }

    .item-total {
      cursor: pointer;
      padding-right: 13px;
      margin-right: 35px;

      &:not(.no-caret)::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        width: 12px;
        height: 12px;
        margin: -6px 0 0 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../../assets/caret-down.svg);
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 5px 0;

        li {
          display: block;
          padding: 10px 25px;
          cursor: pointer;
          color: $color-text-primary;
          font-weight: 500;

          &:hover {
            background: rgba(197, 178, 240, 0.5) !important;
          }
        }
      }

      @media (max-width: 440px) {
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
    }

    .item-nav {
      a {
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        margin-right: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &.prev {
          background-image: url(../../assets/chevron-left.svg);

          &.loading {
            width: 20px;
            height: 20px;
            margin-top: 2px;
            background-image: url(../../assets/loading-forever.gif);
          }
        }

        &.next {
          background-image: url(../../assets/chevron-right.svg);

          &.loading {
            width: 20px;
            height: 20px;
            margin-top: 2px;
            background-image: url(../../assets/loading-forever.gif);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
