@import '../../styles/base.scss';

.auth-reset-pass-container {
  background-color: #fdfcfc;
  width: 100%;
  height: 100%;
  overflow: auto;

  .heading {
    h3 {
      color: $heading-default-color;
    }

    p {
      color: $heading-summary-default-color;
    }
  }

  .inner-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    text-align: center;
  }

  .auth-box {
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    box-shadow: 0px 15px 50px rgba(131, 134, 157, 0.25);
    border-radius: 6px;
    padding: 30px;
    margin: 0 auto;
  }

  .logo img {
    height: 40px;
  }

  .btn-submit {
    width: 100%;
  }

  @media (max-width: 500px) {
    background-color: $color-white !important;

    .inner-content {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0 !important;
    }

    .auth-box {
      box-shadow: none;
      border-radius: 0;
      max-width: 100% !important;
      padding: 30px 15px;
    }
  }
}

.auth-reset-sent {
  background-color: #f2f2f2;

  .inner-content {
    padding: 0 50px;
    text-align: center !important;
  }

  .auth-box {
    display: inline-block;
  }

  .auth-box img {
    height: 60px;
  }

  .btn {
    width: 150px;
  }
}
