$color-white: #ffffff;
$color-black: #000000;

$color-text-default: #000000;
$color-text-primary: #6932d4;

$color-links-default: rgba(3, 41, 64, 0.86);
$color-links-default--hover: rgba(0, 0, 0, 0.86);

$color-brand-primary: #5828ba;

$input-border-color: #dbe2ea;
$input-border-color--active: #dbe2ea;

$btn-primary-background-color: #6932d4;
$btn-primary-border-color: #6932d4;

$btn-grey-background-color: #e2e2e2;
$btn-grey-border-color: #e2e2e2;

$btn-danger-background-color: #ff9292;
$btn-danger-border-color: #ff9292;
$btn-danger-text-color: #fd0000;

$heading-default-color: #2a2834;
$heading-summary-default-color: rgba(0, 0, 0, 0.5);

$onboarding-nav-background-color: #0f0919;

$color-success: rgb(14, 203, 129);
$color-failed: #b00020;
