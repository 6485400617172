@charset "UTF-8";

@import './variables';
@import './typography';
@import './include-media';
@import './colors';

@-ms-viewport {
  width: device-width;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  background: $color-white;
  font-size: $font-size-default;
  color: $color-text-default;
  font-weight: $font-weight-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  font-family: $primary-font-family;
  font-style: $font-style-default;

  iframe {
    display: none !important;
  }
}

#root {
  position: relative;
}

a {
  color: $color-links-default;
  text-decoration: none;
  font-weight: 500;
}
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}
a:hover {
  color: $color-links-default--hover;
  text-decoration: none;
}

p {
  font-family: $primary-font-family;
  font-size: $font-size-default;
  font-weight: 400;
}

.h-100p {
  height: 100% !important;
}

.max-w-250x {
  max-width: 250px !important;
}
.max-w-350x {
  max-width: 350px !important;
}
.max-w-500x {
  max-width: 500px !important;
}
.max-w-400x {
  max-width: 400px !important;
}
.max-w-450x {
  max-width: 450px !important;
}
.max-w-600x {
  max-width: 600px !important;
}
.max-w-700x {
  max-width: 700px !important;
}
.max-w-800x {
  max-width: 800px !important;
}

.margin-none {
  margin: 0 !important;
}
.margin-auto_center {
  margin: 0 auto !important;
}

.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.no-scroll {
  overflow: hidden !important;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

.hidden {
  display: none !important;
}
.invisible {
  opacity: 0 !important;
  filter: opacity(0);
}

.centered {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.centered:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.centered .centered-content {
  vertical-align: middle;
  max-width: 98%;
  display: inline-block;
  text-align: left !important;
}

[data-vertical_center],
[data-vertical_center='true'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

[data-vertical_end],
[data-vertical_end='true'] {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.full-width {
  width: 100% !important;
}
.full-height {
  height: 100% !important;
}
.divider-right--full-width {
  border-right: 1px solid #e2e1e1;
}

.Toastify__toast {
  min-height: 40px !important;
  border-radius: 3px !important;
  padding: 8px 20px !important;
}
.Toastify__toast-container--top-center {
  width: 100% !important;
  max-width: 320px !important;
  top: 1em !important;
  left: 50% !important;
}
.Toastify__toast--default {
  color: #505050 !important;
}

@media (max-width: 576px) {
  .full-height-sm-none {
    height: auto !important;
  }
  .full-width-sm-none {
    width: auto !important;
  }
  .overflow-y-sm-none {
    overflow-y: unset !important;
  }
  .table-responsive-sm {
    white-space: nowrap;
  }
}

.cursor-auto {
  cursor: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-none {
  cursor: none;
}
.cursor-context-menu {
  cursor: context-menu;
}
.cursor-help {
  cursor: help;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-progress {
  cursor: progress;
}
.cursor-wait {
  cursor: wait;
}
.cursor-cell {
  cursor: cell;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-text {
  cursor: text;
}
.cursor-vertical-text {
  cursor: vertical-text;
}
.cursor-alias {
  cursor: alias;
}
.cursor-copy {
  cursor: copy;
}
.cursor-move {
  cursor: move;
}
.cursor-no-drop {
  cursor: no-drop;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-all-scroll {
  cursor: all-scroll;
}
.cursor-col-resize {
  cursor: col-resize;
}
.cursor-row-resize {
  cursor: row-resize;
}
.cursor-n-resize {
  cursor: n-resize;
}
.cursor-e-resize {
  cursor: e-resize;
}
.cursor-s-resize {
  cursor: s-resize;
}
.cursor-w-resize {
  cursor: w-resize;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-ew-resize {
  cursor: ew-resize;
}
.cursor-ne-resize {
  cursor: ne-resize;
}
.cursor-nw-resize {
  cursor: nw-resize;
}
.cursor-se-resize {
  cursor: se-resize;
}
.cursor-sw-resize {
  cursor: sw-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}

.vgap--1x {
  height: 0.1rem;
}
.vgap--2x {
  height: 0.2rem;
}
.vgap--3x {
  height: 0.3rem;
}
.vgap--4x {
  height: 0.4rem;
}
.vgap--5x {
  height: 0.5rem;
}
.vgap-1x {
  height: 1rem;
}
.vgap-2x {
  height: 2rem;
}
.vgap-3x {
  height: 3rem;
}
.vgap-4x {
  height: 4rem;
}
.vgap-5x {
  height: 5rem;
}

.hgap--1x {
  display: inline-block;
  vertical-align: middle;
  width: 0.1rem;
}
.hgap--2x {
  display: inline-block;
  vertical-align: middle;
  width: 0.2rem;
}
.hgap--3x {
  display: inline-block;
  vertical-align: middle;
  width: 0.3rem;
}
.hgap--4x {
  display: inline-block;
  vertical-align: middle;
  width: 0.4rem;
}
.hgap--5x {
  display: inline-block;
  vertical-align: middle;
  width: 0.5rem;
}
.hgap-1x {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
}
.hgap-2x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-3x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-4x {
  display: inline-block;
  vertical-align: middle;
  width: 4rem;
}
.hgap-5x {
  display: inline-block;
  vertical-align: middle;
  width: 5rem;
}

.pt--1x {
  padding-top: 0.1rem;
}
.pt--2x {
  padding-top: 0.2rem;
}
.pt--3x {
  padding-top: 0.3rem;
}
.pt--4x {
  padding-top: 0.4rem;
}
.pt--5x {
  padding-top: 0.5rem;
}
.pt-1x {
  padding-top: 1rem;
}
.pt-2x {
  padding-top: 2rem;
}
.pt-3x {
  padding-top: 2rem;
}
.pt-4x {
  padding-top: 4rem;
}
.pt-5x {
  padding-top: 5rem;
}

.px-25x {
  padding-left: 25px;
  padding-right: 25px;
}

input.form-control,
textarea.form-control,
select.form-control {
  border: 1px solid $input-border-color;
  box-shadow: none !important;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
  border-radius: 6px !important;
}
input.form-control:focus,
textarea.form-control:focus,
input.form-control:active,
textarea.form-control:active {
  border: solid 1px $input-border-color--active !important;
}

.sel-custom {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../assets/dropdown.svg);
  }
}

.btn {
  font-size: $btn-font-size-default !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  font-weight: 400;
  padding-top: 0.3rem !important;

  &.btn-width-150px {
    width: 150px;
  }
  &.btn-width-100px {
    width: 100px;
  }
}
a.btn {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  opacity: 1 !important;
}
.btn-primary {
  background-color: $btn-primary-background-color !important;
  border-color: $btn-primary-border-color !important;

  &:hover,
  &::after,
  &:active {
    background-color: $btn-primary-background-color !important;
    border-color: $btn-primary-border-color !important;
  }

  &.btn-outline {
    background-color: transparent !important;
    color: $color-text-primary;
  }

  &.light {
    border-width: 0;
    color: #6932d4 !important;
    background: rgba(197, 178, 240, 0.5) !important;
    border-color: rgba(197, 178, 240, 0.5) !important;
  }
}
.btn-grey {
  background-color: $btn-grey-background-color !important;
  border-color: $btn-grey-border-color !important;

  &:hover,
  &::after,
  &:active {
    background-color: $btn-grey-background-color !important;
    border-color: $btn-grey-border-color !important;
  }

  &.btn-outline {
    background-color: transparent !important;
    color: $color-text-primary;
  }
}
.btn-outline {
  background-color: transparent;
  &:hover,
  &::after,
  &:active {
    background-color: transparent;
  }
}
.btn-danger {
  background-color: $btn-danger-background-color !important;
  border-color: $btn-danger-border-color !important;
  color: $btn-danger-text-color;

  &:hover,
  &:active,
  &::after {
    background-color: $btn-danger-background-color !important;
    border-color: $btn-danger-border-color !important;
    color: $btn-danger-text-color !important;
  }

  &.btn-outline {
    background-color: transparent !important;
    color: $btn-danger-text-color;
  }
}
.btn-swap {
  position: relative;
  padding-left: 40px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    margin: -10px 0 0 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../assets/nav-swap.svg);
  }
}

@media (max-width: 768px) {
  .btn-float-mobile {
    position: absolute;
    bottom: 25px;
    right: 25px;
    height: 50px;
    width: 50px;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100% !important;
    font-weight: bold;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .btn-full-width-mobile {
    width: 100% !important;
  }

  .full-width-mobile {
    width: 100% !important;
    max-width: 100% !important;
  }
}

[placeholder] {
  text-overflow: ellipsis;
}
::-moz-placeholder {
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}
::-webkit-input-placeholder {
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
  transition: background-color 5000s ease-in-out 0s;
}

//removes spinners from number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
}

.text-muted {
  color: $text-muted-color !important;
}

.divider-text {
  position: relative;
  color: $color-black;
}
.divider-text span {
  position: relative;
  z-index: 99;
  display: inline-block;
  width: 50px;
  font-weight: 600;
  background: $color-white;
}
.divider-text::after {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin: 0 0 0 -0.5px;
  background-color: #f2f0fb;
}

.swal2-popup {
  width: 100%;
  max-width: 500px;
}
.swal2-popup .swal2-html-container {
  font-size: $font-size-default !important;
  overflow: visible !important;
}
.swal2-popup .swal2-close {
  z-index: 99;
  font-size: 25px !important;
  font-weight: bold;
  color: #000000;
  border: 0;
  box-shadow: none !important;
  background-color: #e9e9e9;
  padding: 0.3rem 1rem;
  margin: 0.6rem;
  border-radius: 50px;
}
.modal-default {
  width: 100% !important;
  border-radius: 4px !important;
}
.modal-default .swal2-html-container {
  text-align: left !important;
  overflow: visible !important;
}
@media (max-width: 400px) {
  .modal-mobile-fullscreen {
    padding: 0 !important;
    border-radius: 0 !important;
    // top: 75px;

    .swal2-popup {
      max-width: 100% !important;
      border-radius: 0 !important;
      max-height: 100%;
      overflow: auto;
    }
  }

  .swal2-popup .swal2-close {
    top: 25px;
    right: 30px;
  }
}

.otp-input-container {
  width: 100%;
}
.otp-input-container div {
  display: block !important;
  flex: 1;
  margin: 5px;
}
.otp-input-container div input {
  width: 100% !important;
  height: 50px;
}

.note-type-1 {
  position: relative;
  border-left: 3px solid #9801cc;
  padding: 5px 15px 5px 25px;
  font-size: 14px;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &.caution::before {
    background-image: url(../assets/caution.svg);
  }
}

.color-171717 {
  color: #171717 !important;
}
.color-4F4F4F {
  color: #4f4f4f !important;
}
.color-fd0000 {
  color: #fd0000 !important;
}
.color-9e9e9e {
  color: #9e9e9e !important;
}

.color-r7-g12-b66-a60 {
  color: rgba(7, 12, 66, 0.6) !important;
}

.table-basic {
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
  border-radius: 3px;
  min-width: 600px;

  table {
    width: 100%;

    th {
      padding: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #6932d4;

      &:first-child {
        padding-left: 25px;
      }

      &:last-child {
        padding-right: 25px;
      }
    }

    td {
      padding: 15px 0;

      &:first-child {
        padding-left: 25px;
      }

      &:last-child {
        padding-right: 25px;
      }

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      }
    }

    .delete {
      padding: 2px 8px;
      border-radius: 8px !important;
      color: white !important;
      font-weight: bold;
    }

    .edit {
      padding: 2px 8px;
      border-radius: 8px !important;
      color: white !important;
      font-weight: bold;
    }
  }

  .no-result {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

label {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.custom-radio .rdio {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-top: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #7b8c97;
  background-image: linear-gradient(to bottom, #f5f5f5, #ffffff);
}
.custom-radio input[type='radio'] {
  opacity: 0;
  display: none;
}
.custom-radio input[type='radio']:checked + .rdio {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: solid 4px $color-brand-primary;
  background-color: $color-brand-primary;
}

.default-checkbox {
  cursor: pointer !important;
  display: flex;
  align-items: center;

  label {
    cursor: pointer !important;
  }
}

.custom-checkbox {
  cursor: pointer;
}
.custom-checkbox .chkr {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 14px;
  height: 14px;
  margin-top: 2px;
  border-radius: 2px;
  border: solid 1px #7b8c97;
  background-image: linear-gradient(to bottom, #f5f5f5, #ffffff);
}
.custom-checkbox input[type='checkbox'] {
  opacity: 0;
  display: none;
}
.custom-checkbox input[type='checkbox']:checked + .chkr {
  border: solid 1px $color-brand-primary;
  background-image: unset;
  background-color: $color-brand-primary;
}
.custom-checkbox input[type='checkbox']:checked + .chkr::before {
  content: '✔';
  font-family: -apple-system, arial, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  top: -1px;
  left: -1px;
  width: 14px;
  height: 14px;
  line-height: 14px;
  font-size: 10px;
  color: #ffffff;
  text-align: center;
}

.custom-toogle-switch {
  transition: all 0.2s ease;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  position: relative;
  background: #f2f2f2;
  width: 40px;
  height: 20px;
  border-radius: 40px;
}
.custom-toogle-switch::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 2px;
  width: 15px;
  height: 15px;
  margin: -7.5px 0 0 0;
  border-radius: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.01), 0px 3px 1px rgba(0, 0, 0, 0.03);
}
.custom-toogle-switch.switch-on {
  background-color: $color-brand-primary;
}
.custom-toogle-switch.switch-on::before {
  left: unset;
  right: 2px;
}

.setting-radio-list {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;

  > .title {
    padding: 10px 35px;
    border-bottom: 1px solid #e6e6e6;

    h4 {
      margin: 0;
      padding: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0 25px;

      div.wrap {
        padding: 15px 10px;
        border-bottom: 1px solid #e6e6e6;
      }

      &:last-child div.wrap {
        border-bottom: 0;
      }
    }
  }
}

.no-pointer-events {
  pointer-events: none;
  user-select: none;
}

.table-advanced {
  h3,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }

  .more {
    color: #6932d4;
  }

  .table-pagination {
    text-align: right;

    .pagination-wrap {
      display: inline-block;

      .item-total,
      .item-nav {
        position: relative;
        display: inline-block;
        vertical-align: top;
      }

      .item-total {
        cursor: pointer;
        padding-right: 13px;
        margin-right: 35px;

        &:not(.no-caret)::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          width: 12px;
          height: 12px;
          margin: -6px 0 0 0;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url(../assets/caret-down.svg);
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 5px 0;

          li {
            display: block;
            padding: 10px 25px;
            cursor: pointer;
            color: $color-text-primary;
            font-weight: 500;

            &:hover {
              background: rgba(197, 178, 240, 0.5) !important;
            }
          }
        }

        @media (max-width: 440px) {
          margin-right: 0 !important;
          margin-bottom: 15px;
        }
      }

      .item-nav {
        a {
          cursor: pointer;
          display: inline-block;
          vertical-align: top;
          width: 24px;
          height: 24px;
          margin-right: 20px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;

          &.prev {
            background-image: url(../assets/chevron-left.svg);

            &.loading {
              width: 20px;
              height: 20px;
              margin-top: 2px;
              background-image: url(../assets/loading-forever.gif);
            }
          }

          &.next {
            background-image: url(../assets/chevron-right.svg);

            &.loading {
              width: 20px;
              height: 20px;
              margin-top: 2px;
              background-image: url(../assets/loading-forever.gif);
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .btn-filters-set {
    button {
      margin-right: 15px;
      padding-left: 15px;
      padding-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        margin-top: -3px;
        max-height: 14px;
        margin-right: 5px;
      }

      &.report img {
        height: 6px;
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }

  table {
    width: 100%;

    th {
      padding: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #6932d4;

      &:first-child {
        padding-left: 25px;
      }

      &:last-child {
        padding-right: 25px;
      }
    }

    tbody tr {
      border-bottom: 0.75px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: 0;
      }
    }

    td {
      padding: 15px 0;

      &:first-child {
        padding-left: 25px;
      }

      &:last-child {
        padding-right: 25px;
      }

      .truncate-text {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
      }

      @media (max-width: 768px) {
        &.detail-pointer {
          cursor: pointer;
        }

        &:not(.hidden-) {
          pointer-events: unset;
        }
      }
    }

    .btn {
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  .no-result {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-note {
  position: relative;
  background: rgba(166, 230, 239, 0.2);
  border-radius: 8px;
  padding: 20px 25px;
  padding-left: 50px;

  &::before {
    content: '';
    position: absolute;
    top: 25px;
    left: 25px;
    width: 15px;
    height: 15px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../assets/info.svg);
  }
}

.custom-tab {
  .tab-header {
    display: flex;
    width: 100%;

    .tab-item {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      flex: 1;
      padding: 15px 0;
      border-bottom: 3px solid #e8e8e8;

      &.current {
        border-bottom: 3px solid #6932d4;
      }
    }
  }
}

.dropdown-custom {
  border: 0;
  background-color: #ffffff;
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
  border-radius: 3px;
}

.btn-google-login {
  cursor: pointer;
  position: relative;
  font-weight: 400;
  padding-left: 40px;
}
.btn-google-login::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 0;
  background-image: url(../assets/google-login-logo.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.rc-dropdown .rc-menu {
  padding: 15px 0;
  font-size: $font-size-default;
  max-width: 300px;

  > li {
    cursor: pointer;
    padding: 10px 15px;
    font-family: $primary-font-family;

    &:hover {
      background-color: #ede9fb;
      color: $color-text-primary;
    }
  }
}
.rc-menu-submenu {
  padding: 0 !important;

  .rc-menu-submenu-title {
    padding: 10px 15px !important;
    color: $color-text-primary;
    background-color: #ede9fb !important;

    &:hover {
      background-color: #ede9fb !important;
    }
  }

  &:hover,
  &.rc-menu-submenu-open,
  &.rc-menu-submenu-selected {
    background-color: #ede9fb !important;
  }

  &.rc-menu-submenu-active {
    background-color: #ede9fb !important;
  }

  .rc-menu-item {
    cursor: pointer;
  }

  .rc-menu-item-active,
  .rc-menu-item-selected {
    color: $color-text-primary;
    background-color: #ede9fb !important;
  }
}

.dashboard-wrapper {
  min-width: 1200px;

  .dashboard-page {
    display: flex;
    justify-content: stretch;
    align-items: center;

    .dashboard-sidebar {
      width: 100%;
      max-width: 230px;
    }

    .dashboard-content {
      width: calc(100% - 230px);

      @media (max-width: 1200px) {
        width: 100%;
      }
    }
  }
}

.visible-1200px {
  display: none !important;
}

@media (max-width: 1200px) {
  .dashboard-wrapper,
  .onboarding-wrapper {
    min-width: 100%;

    .dashboard-sidebar {
      top: 75px;
      left: 0;
      position: fixed !important;
      transition: all 0.25s ease-in-out;
      transform: translate(-230px, 0);
    }

    &.left-sidebar-open,
    &.right-sidebar-open {
      position: relative;

      .sidebar-backdrop {
        position: absolute;
        z-index: 1088;
        top: 75px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        user-select: none;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &.left-sidebar-open {
      .dashboard-sidebar {
        z-index: 1090;
        transform: translate(0, 0);
      }
    }

    .right-sidebar {
      top: 75px;
      right: 0;
      width: 100%;
      max-width: 230px;
      height: 100vh;
      overflow: auto;
      padding: 25px 0;
      transition: all 0.25s ease-in-out;
      position: fixed !important;
      background-color: $onboarding-nav-background-color;
      transform: translate(230px, 0);
    }

    &.right-sidebar-open {
      .right-sidebar {
        z-index: 1090;
        transform: translate(0, 0);
      }
    }
  }

  .visible-1200px {
    display: block !important;
  }

  .hidden-1200px {
    display: none !important;
  }
}

.visible-870px {
  display: none !important;
}

@media (max-width: 870px) {
  .visible-870px {
    display: block !important;
  }

  .hidden-870px {
    display: none !important;
  }
}

.visible-768px {
  display: none !important;
}

@media (max-width: 768px) {
  .visible-768px {
    display: block !important;

    &.visible-inline {
      display: inline !important;
    }
  }

  .hidden-768px {
    display: none !important;
  }
}

.visible-340px {
  display: none !important;
}

@media (max-width: 340px) {
  .visible-340px {
    display: block !important;
  }

  .hidden-340px {
    display: none !important;
  }
}

.spinner-animation {
  animation: spinner 0.7s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingPlaceholders {
  0% {
    background-color: lightgray;
  }
  50% {
    background-color: #e5e5e5;
  }
  100% {
    background-color: lightgray;
  }
}
@keyframes loadingPlaceholders {
  0% {
    background-color: #f9f9f9;
  }
  50% {
    background-color: #e5e5e5;
  }
  100% {
    background-color: #f9f9f9;
  }
}

.custom-placeholder {
  display: block;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  -webkit-animation: loadingPlaceholders 1.5s ease-in infinite;
  animation: loadingPlaceholders 1.5s ease-in infinite;
  background-color: #e5e5e5;
}

.table-filters-container {
  min-height: 100px;
  padding: 25px 15px;
  background: #ffffff;
  border-bottom: 0.75px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 15%);
  border-radius: 3px;
}

.cleave-currency-wrap {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding-left: 13px;
  gap: 5px;

  .symbol {
    margin-top: -1px;
  }

  input {
    border: 0 !important;
    padding-left: 0;

    &:focus {
      border: 0 !important;
      box-shadow: none !important;
    }
  }

  border: 1px solid #dbe2ea;
  border-radius: 6px;
}
