@import '../../../styles/base.scss';

.transaction-info-dialog-popup {
  padding: 0 !important;

  .transaction-info-dialog-container {
    margin: 0 !important;
  }

  .head {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .illus {
      margin-left: 25px;

      img {
        height: 24px;
      }
    }

    h4 {
      position: relative;
      padding: 0 25px 0 15px;
      margin: 0;

      &::before {
        display: none;
        content: ' ';
        position: absolute;
        top: 50%;
        left: 25px;
        width: 8px;
        height: 8px;
        margin: -4px 0 0 0;
        border-radius: 100%;
        vertical-align: middle;
      }

      &.success::before {
        background-color: $color-success;
      }
      &.failed::before {
        background-color: $color-failed;
      }
      &.pending {
        background-color: var(--bs-warning);
      }
      &.in_progress {
        background-color: var(--bs-primary-border-subtle);
      }
    }
  }

  .content {
    padding: 20px 25px 20px;
  }

  .buttons {
    display: flex;
    padding: 0 25px 30px;
  }

  .info-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    .info-title {
      font-weight: bold;
    }

    .info-content {
      a {
        color: #6932d4;
        border-bottom: 1px dotted #6932d4;
      }

      &.success {
        color: $color-success;
        font-weight: 500;
      }
      &.failed {
        color: $color-failed;
        font-weight: 500;
      }
      &.pending {
        color: var(--bs-warning);
        font-weight: 500;
      }
      &.in_progress {
        color: var(--bs-primary-border-subtle);
        font-weight: 500;
      }
    }

    .left {
      width: 40%;
    }

    .right {
      max-width: 70%;
      text-align: right;
    }

    &.block {
      display: block;
      align-items: unset;
      justify-content: unset;

      .right {
        max-width: 100%;
        text-align: left;
      }
    }
  }
}
