.deposit-dialog-popup {
  padding: 0 !important;

  .deposit-dialog-container {
    margin: 0 !important;
  }

  .head {
    padding: 0 25px;
  }

  .content {
    max-height: 400px;
    overflow: auto;
    padding: 0 25px;
    padding-top: 25px;
  }

  .buttons-area {
    padding: 25px;
  }

  .amount-content {
    max-width: 400px;
  }

  .deposit-content {
    .details {
      padding: 0 25px;

      .detail__item {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .detail__title {
        font-weight: 600;
      }

      .detail__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .copiable {
        span {
          max-width: 90%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          display: inline-block;
          vertical-align: top;
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }
    }

    .qr-display {
      text-align: center;
      border: 1px solid #b9b9b9;
      border-radius: 8px;

      img {
        height: 200px;
      }
    }
  }

  .deposit-methods-chooser {
    padding: 45px 25px 15px;

    .head {
      display: flex;
      justify-content: stretch;
      align-items: center;

      h4 {
        padding: 0;
        margin: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
        border-bottom: 1px solid #e2e1e1;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
