@import '../../../styles/base.scss';
@import '../settings.scss';

.team-settings-list {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px 20px;
      background: #f3f3f3;
      border-radius: 8px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: inline-block;
      }

      h6 {
        display: inline-block;
        padding: 0;
        margin: 0;
      }

      .remove {
        background: #ff9292 !important;
        border-radius: 8px !important;
        color: #9c0000 !important;
        text-transform: uppercase;
        font-size: 11px !important;
        padding: 0 7px 3px;

        &:hover,
        &:active {
          color: #9c0000 !important;
        }
      }

      .edit {
        background: $color-brand-primary !important;
        color: $color-white !important;
        border-radius: 8px !important;
        text-transform: uppercase;
        font-size: 11px !important;
        padding: 0 7px 3px;
      }
    }
  }

  .no-result {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
