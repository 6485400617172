@import '../../../styles/base.scss';

ul.security-settings-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .wrap {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 25px;
  }
}

.twofa-setup-1 {
  .chooser {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0;
      border-bottom: 1px solid #e2e1e1;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .qr-display {
    text-align: center;
    border: 1px solid #b9b9b9;
    border-radius: 8px;

    img {
      height: 200px;
    }
  }

  .otp-input-container div input {
    text-transform: uppercase;
    border-width: 0;
    background: #f1f1f1;
    border-radius: 12px;

    &:focus,
    &:active {
      border-color: #6932d4 !important;
      border-width: 1px;
    }
  }
}
