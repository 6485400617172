@import '../../styles/base.scss';

.auth-ra-container {
  background-color: #fdfcfc;
  width: 100%;
  height: 100%;
  overflow: auto;

  .heading {
    h3 {
      color: $heading-default-color;
    }

    p {
      color: $heading-summary-default-color;
    }
  }

  .inner-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
  }

  .ra-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .illus {
      position: relative;
      text-align: left;

      h4 {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -10px;
          left: 0;
          height: 5px;
          width: 90px;
          background-color: $color-text-primary;
        }
      }

      img {
        max-height: 500px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -60px;
        left: -60px;
        width: 120px;
        height: 120px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(../../assets/candlesticks.svg);
      }
    }
  }

  .ra-box {
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    box-shadow: 0px 15px 50px rgba(131, 134, 157, 0.25);
    border-radius: 6px;
    padding: 30px;
    margin: 0;
  }

  .logo img {
    height: 40px;
  }

  .btn-submit {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    .ra-wrap .illus {
      display: none;
    }
  }

  @media (max-width: 500px) {
    background-color: $color-white !important;

    .inner-content {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0 !important;
    }

    .ra-box {
      box-shadow: none;
      border-radius: 0;
      max-width: 100% !important;
      padding: 30px 15px;
    }
  }
}
