@import '../../styles/base.scss';

.assets-dashboard {
  height: 100vh;
  overflow: auto;
}

.assets-page-wrap {
  padding: 25px 0;
  padding-top: 0;

  .table-advanced {
    padding: 0 25px;

    .table-wrapper {
      background-color: #ffffff;
      box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
      border-radius: 3px;

      .truncate-text {
        width: 100%;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .assets-balance-info {
    padding: 0 25px;

    .t {
      font-weight: 500;
      color: $text-muted-color;
    }

    .b {
      .balance-num {
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        font-size: 45px;
        padding-right: 10px;
        transition: all 0.25s ease-in-out;

        @media (max-width: 500px) {
          &.shrink-mobile {
            font-size: 30px;
          }
        }
      }

      span {
        display: inline-block;
        vertical-align: top;
        margin-top: 8px;
        color: #9e9e9e;
        font-weight: normal;
        font-size: 25px;
      }

      @media (max-width: 340px) {
        span {
          margin-top: 0;
          margin-bottom: 5px;
        }
      }
    }
    .a {
      color: $color-text-default;
    }

    h3 {
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    .table-wrapper {
      background-color: transparent !important;
      box-shadow: none !important;

      th {
        display: none;
      }
      td {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
