@import '../../styles/base.scss';

.onboarding-account-type {
  width: 100%;
  height: 100%;
  overflow: auto;

  .heading {
    h3,
    h4 {
      color: $heading-default-color;
    }

    p {
      color: $heading-summary-default-color;
    }
  }

  .logo img {
    height: 40px;
  }

  .inner-content {
    padding: 0 50px;
    text-align: center !important;
  }

  .content-box {
    width: 100%;
    max-width: 550px;
    display: inline-block;
    padding: 25px 0;
  }

  .account-types {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .account-types a {
    cursor: pointer;
    position: relative;
    display: block;
    user-select: none;
    background: $color-white;
    box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
    border-radius: 3px;
    text-align: left;
    padding: 30px 35px;
    padding-left: 120px;
    border: 2px solid #ffffff;
  }
  .account-types li {
    margin-bottom: 20px;

    &.selected a {
      border-color: $color-brand-primary;
    }
  }
  .account-types li:last-child {
    margin-bottom: 0;
  }
  .account-types li a::before {
    content: '';
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 35px;
    margin: -30px 0 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .account-types li.individual a::before {
    background-image: url(../../assets/People-2.svg);
  }
  .account-types li.corporate a::before {
    background-image: url(../../assets/GoldBricks-1.svg);
  }
  .account-types li.trust a::before {
    background-image: url(../../assets/Safe-2.svg);
  }

  @media (max-width: 400px) {
    .account-types a {
      padding: 30px 25px;
      padding-top: 120px;
      text-align: center;

      &::before {
        top: 35px !important;
        left: 50% !important;
        margin: 0 0 0 -30px !important;
      }
    }
  }
}

.onboarding-wrapper {
  display: flex;
  justify-content: stretch;
  align-items: center;

  .onboarding-sidebar {
    width: 100%;
    max-width: 400px;
    overflow: auto;
    background-color: $onboarding-nav-background-color;

    @media (max-width: 1200px) {
      position: fixed;
      top: 75px;
      bottom: 0;
      transition: all 0.25s ease-in-out;
      transform: translate(-400px, 0);
    }

    @media (max-width: 400px) {
      max-width: 80%;
      transform: translate(-100%, 0);
    }
  }

  &.left-sidebar-open {
    @media (max-width: 1200px) {
      .onboarding-sidebar {
        z-index: 1090;
        transform: translate(0, 0);
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
}

.onboarding-content-wrap {
  height: 100vh;
  width: 100%;
  overflow: auto;
  min-width: 600px;

  .content-inner {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 25px;
    height: 100%;

    h3 {
      width: 100%;
    }

    .centered-content {
      width: 100%;
    }
  }

  form {
    max-width: 500px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .button-set {
    .btn {
      min-width: 150px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 768px) {
      .hgap-1x {
        display: none;
      }

      .d-inline-block {
        width: 100%;
        display: flex !important;
        justify-content: stretch;
        align-items: center;
        gap: 10px;
      }

      .btn {
        min-width: unset;
        width: 100%;
      }
    }

    @media (max-width: 400px) {
      .d-inline-block {
        .btn:first-child {
          display: none;
        }
      }
    }
  }

  &.in-page {
    overflow: unset !important;

    .content-inner {
      display: flex;
      justify-content: stretch;
      align-items: center;
      max-width: unset;
      margin: 0;
      padding: 0;

      .in-page-nav {
        width: 100%;
        height: 100%;
        overflow: auto;
        max-width: 200px;

        ul {
          list-style: none;
          margin: 0;
          padding: 45px 25px;

          li {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          a {
            cursor: pointer;
            display: block;
            padding: 8px 25px;

            &.current {
              background-color: #ede9fb;
              color: $color-text-primary;
              border-radius: 6px;
            }
          }
        }

        @media (max-width: 768px) {
          height: auto;
          max-width: 100%;
          white-space: nowrap;
          overflow: auto;
          border-bottom: 1px solid #e2e1e1;

          ul {
            margin-top: 15px;
            padding: 0 25px;

            li {
              display: inline-block;
              margin-right: 25px;

              a {
                padding: 8px 0;

                &.current {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }

      .in-page-content {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 45px 25px;
      }

      @media (max-width: 768px) {
        display: block;

        .in-page-content {
          height: auto;
          overflow: unset;
        }
      }
    }
  }

  .kyc-stakeholders-list {
    max-width: 500px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 25px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 10px 20px;
        background: #f3f3f3;
        border-radius: 8px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          cursor: pointer;
        }

        h6 {
          display: inline-block;
          padding: 0;
          margin: 0;
        }

        .remove {
          background: #ff9292 !important;
          border-radius: 8px !important;
          color: #9c0000 !important;
          text-transform: uppercase;
          font-size: 12px !important;
          padding: 2px 7px;

          &:hover,
          &:active {
            color: #9c0000 !important;
          }
        }
      }
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .id-files {
    margin: 25px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;

    .control {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      border: 4px dotted #e2e1e1;
      color: #888888;
      border-radius: 6px;
    }

    .file {
      display: inline;
      vertical-align: top;
      width: 80px;
      height: 80px;

      .file-grid {
        margin-right: 15px;
        margin-bottom: 15px;
        display: inline-block;
        vertical-align: top;
        position: relative;
      }
      .file-grid:last-child {
        margin-right: 0;
      }
      .file-grid .display img {
        max-width: 100%;
        height: 80px;
        object-fit: cover;
      }

      .file-progress {
        width: 10%;
        height: 4px;
        margin-top: 5px;
        transition: all 0.25s ease-in-out;
        background-color: #23991f;

        &.progress-error {
          background-color: #e94e4e;
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-width: 100%;
  }
}
