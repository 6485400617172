@import '../../styles/base.scss';

.error-container {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  overflow: auto;

  .inner-content {
    color: $color-black;
    text-align: center;
    width: 100%;
    max-width: 420px;
    padding: 0 25px;
    margin: 0 auto;
  }
}
