@import '../../../styles/colors.scss';

.withdrawal-dialog-popup {
  padding: 0 !important;

  .withdrawal-dialog-container {
    margin: 0 !important;
  }

  .back-button {
    position: absolute;
    top: 15px;
    left: 22px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../../../assets/arrow-left.svg);
  }

  .head {
    position: relative;
    padding: 0 25px;
    margin-top: 5px;
    text-align: center;
    color: $color-black;
  }

  .content {
    padding: 25px;
  }

  .recipient-list-wrap {
    max-height: 500px;
    overflow: auto;
  }

  ul.recipient-select-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      gap: 15px;
      display: flex;
      align-items: center;
      justify-content: stretch;
      cursor: pointer;
      border-bottom: 1px solid #e2e2e2;
      padding: 15px 0;

      &:last-child {
        border-bottom: 0;
      }

      .initials {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &.img-individual {
          background-image: url(../../../assets/Personal.svg);
        }

        &.img-business {
          background-image: url(../../../assets/Business.svg);
        }
      }

      .title {
        gap: 5px;
        display: flex;
        align-items: flex-start;
        justify-content: stretch;

        h5 {
          margin: 0;
          padding: 0;
          color: $color-black;
        }
      }
    }
  }

  .empty-state {
    text-align: center;

    img {
      height: 70px;
    }

    h6 {
      margin: 25px 0 15px;
    }
  }

  .loading-state {
    text-align: center;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.withdrawal-info-preview {
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e9ec;
    padding: 15px 0;

    .right {
      text-align: right;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
