@import '../../styles/base.scss';

.orders-dashboard {
  height: 100vh;
  overflow: auto;
}

.orders-page-wrap {
  padding: 25px;
  padding-top: 0;

  .table-advanced {
    .table-wrapper {
      background-color: #ffffff;
      box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
      border-radius: 3px;
    }

    tbody tr {
      cursor: pointer;

      &:hover td {
        background-color: rgba(160, 163, 189, 0.15);
      }

      @media (max-width: 768px) {
        &:hover td {
          background-color: unset;
        }
      }
    }
  }
}
