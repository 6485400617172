.global-search-help {
  display: inline;
  position: relative;

  .search-results {
    position: absolute;
    top: 50px;
    right: 0;
    width: 300px;
    max-height: 400px;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        cursor: pointer;
        text-align: left;
        padding: 15px 25px;
        border-bottom: 1px solid #e2e1e1;

        h6 {
          margin: 0;
        }

        p {
          padding: 0;
          margin: 0;
        }

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background: rgba(197, 178, 240, 0.5) !important;
        }
      }
    }

    @media (max-width: 1200px) {
      border-radius: unset;
      position: relative;
      background-color: transparent;
      box-shadow: unset;
      width: 100%;

      ul {
        li {
          padding: 0;
          border-bottom: unset;
          margin-bottom: 25px !important;

          &:hover {
            background: transparent !important;
          }

          h6,
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &:last-child {
            margin-bottom: 40px !important;
          }
        }
      }
    }
  }
}
