@import '../../../styles/base.scss';

.onboarding-nav-content-wrap {
  height: 100vh;
  padding: 25px;
  background-color: $onboarding-nav-background-color;

  .nav-logo {
    padding-left: 25px;
  }
  .logo img {
    height: 38px;
  }

  .centered {
    height: calc(100% - 150px);
  }

  .nav-content {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;

    .title {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .nav-content ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      margin-bottom: 20px;
      padding-left: 25px;

      a {
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          margin: 7px 0 0 0;
          border: 2px solid rgba(223, 223, 223, 0.5);
          border-radius: 100%;
        }

        &.current {
          h6 {
            color: rgba(223, 223, 223, 1);
          }

          span {
            color: rgba(223, 223, 223, 0.7);
          }

          &::before {
            border: 2px solid rgba(223, 223, 223, 1);
          }
        }

        &.done {
          &::before {
            border: 2px solid #40f423;
            background-color: #40f423;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../../../assets/check-mark.svg);
          }
        }
      }

      h6 {
        margin-bottom: 3px;
        color: rgba(223, 223, 223, 0.5);
      }

      span {
        color: rgba(223, 223, 223, 0.5);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .legal {
    opacity: 0.6;
    position: relative;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 25px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      margin: -10px 0 0 0;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../../../assets/legal-frame.svg);
    }
  }

  @media (max-width: 400px) {
    padding: 25px 0 !important;
  }
}
