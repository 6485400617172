@import '../../styles/base.scss';

.auth-signin-container {
  background-color: #fdfcfc;
  width: 100%;
  height: 100%;
  overflow: auto;

  .heading {
    h3 {
      color: $heading-default-color;
    }

    p {
      color: $heading-summary-default-color;
    }
  }

  .inner-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
  }

  .signin-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .illus {
      h4 {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -10px;
          left: 50%;
          height: 5px;
          width: 90px;
          margin: 0 0 0 -45px;
          background-color: $color-text-primary;
        }
      }

      position: relative;
      text-align: center;

      img {
        max-height: 400px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -55px;
        left: 50%;
        width: 120px;
        height: 120px;
        margin: 0 0 0 -60px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(../../assets/illus-1.svg);
      }
    }
  }

  .signin-box {
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    box-shadow: 0px 15px 50px rgba(131, 134, 157, 0.25);
    border-radius: 6px;
    padding: 30px;
    margin: 0;
  }

  .multi-account-box {
    padding: 30px 0 0;

    .logo {
      display: inline-block;
      margin-left: 30px;
    }

    .heading {
      padding: 0 30px;
    }
  }

  .account-choose {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 35px;
    border-top: 1px solid #e2e1e1;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #e2e1e1;
      padding: 25px 30px;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .logo img {
    height: 40px;
  }

  .btn-submit {
    width: 100%;
  }

  .otp-input {
    letter-spacing: 10px;
  }

  .forgot-pass {
    color: $color-text-primary;
  }

  @media only screen and (max-width: 1024px) {
    .signin-wrap .illus {
      display: none;
    }
  }

  @media (max-width: 500px) {
    background-color: $color-white !important;

    .inner-content {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0 !important;
    }

    .signin-box {
      box-shadow: none;
      border-radius: 0;
      max-width: 100% !important;
      padding: 30px 15px;
    }

    .multi-account-box {
      padding: 30px 0;

      .logo {
        margin-left: 0;
      }

      .heading {
        padding: 0 0;
      }
    }
  }
}

.auth-centered {
  background-color: #f2f2f2;

  .inner-content {
    padding: 0 50px;
    text-align: center !important;
  }

  .signin-box {
    display: inline-block;
  }
}
