@import '../../../styles/colors.scss';

.recipient-account-add {
  padding: 0 !important;

  .back-button {
    position: absolute;
    top: 15px;
    left: 22px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-repeat: repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../../../assets/arrow-left.svg);
  }

  .head {
    position: relative;
    padding: 0 25px;
    margin-top: 5px;
    text-align: center;
    color: $color-black;
  }
}

.recipient-info-preview {
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e9ec;
    padding: 15px 0;

    .right {
      text-align: right;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}
