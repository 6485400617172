.price-cards {
  overflow: auto;
  padding: 50px 0;
  margin-bottom: 25px;

  .inner {
    white-space: nowrap;
    padding: 0 25px;
    display: inline-flex;
    gap: 25px;
  }

  .card-wrap {
    display: inline-block;
    vertical-align: top;
    min-width: 250px;
    box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);
    border: 1px solid #ffffff;
    padding: 25px;
    height: auto;
    user-select: none;
    -webkit-user-select: none;
    border-radius: 8px;

    &.card-add-asset {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;

      .icon {
        font-size: 32px;
      }

      .icon,
      p {
        color: #bdbdbd;
      }

      p {
        margin: 0;
        margin-top: 5px;
        font-weight: bold;
      }
    }

    .illus {
      margin-bottom: 15px;

      img {
        height: 40px;
      }
    }
  }

  .info .pair {
    display: flex;
    align-items: center;
    justify-content: stretch;

    .a {
      color: #352e5b;
      font-weight: 600;
    }

    .i {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px;
      width: 16px;
      height: 16px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../../../assets/currecncy-conv.svg);
    }

    .b {
      color: #b9b9b9;
    }
  }

  .price {
    color: #22202d;
    font-weight: 600;
    font-size: 24px;
  }

  .change .c-info {
    display: inline-block;
    max-width: 50px;
    color: #b9b9b9;
    font-weight: 400;
  }

  .positive-change,
  .negative-change {
    position: relative;
    padding-left: 15px;
  }

  .positive-change::before,
  .negative-change::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 5px;
    height: 5px;
    display: inline-block;
    border: 5px solid transparent;
  }

  .positive-change::before {
    border-bottom-color: #4bde97;
    margin-top: -2px;
    transform: translate(0, -50%);
  }
  .negative-change::before {
    border-top-color: #fd0000;
    transform: translate(0, -50%);
    margin-top: 3px;
  }

  .positive-change {
    color: #4bde97;
  }
  .negative-change {
    color: #fd0000;
  }
}
