.table-fiat-accounts {
  table {
    min-width: 768px;
  }

  @media (max-width: 768px) {
    min-width: 100% !important;

    table {
      min-width: 100% !important;

      td:not(.hidden-) {
        padding-left: 15px !important;
      }
      td:last-child {
        text-align: right;
      }
    }
  }
}
