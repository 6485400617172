@import '../../../styles/base.scss';

.dashboard-nav-content-wrap {
  height: 100vh;
  overflow: auto;
  padding: 25px 0;
  background-color: $onboarding-nav-background-color;

  .search-input {
    background: #271b3a;
    border: 1px solid rgba(227, 227, 227, 0.1);
    border-radius: 8px;
    color: #979797;

    &:focus {
      border-color: rgba(227, 227, 227, 0.1) !important;
    }

    &::placeholder {
      color: #979797;
    }
  }

  .nav-logo {
    padding-left: 25px;
  }

  .logo img {
    height: 45px;
  }

  .centered {
    height: calc(100% - 150px);
  }

  .dash-nav-content {
    width: 100%;
    margin: 0 auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        position: relative;
        color: rgba(255, 255, 255, 0.7);
        display: block;
        padding: 15px 25px;
        padding-left: 55px;
        border-left: 5px solid transparent;

        &.alert:not(.current) span {
          position: relative;
          display: inline-block;
          padding-right: 25px;

          &::after {
            @keyframes pulse-red {
              0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
              }

              70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
              }

              100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
              }
            }

            content: '';
            background: rgba(255, 82, 82, 1);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
            animation: pulse-red 2s infinite;
            position: absolute;
            top: 50%;
            right: 0;
            width: 7px;
            height: 7px;
            margin: -2px 0 0 0;
            border-radius: 100%;
            background-color: $color-failed;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 20px;
          width: 20px;
          height: 20px;
          margin: -10px 0 0 0;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        &.home::before {
          background-image: url(../../../assets/nav-home.svg);
        }
        &.assets::before {
          background-image: url(../../../assets/nav-assets.svg);
        }
        &.orders::before {
          background-image: url(../../../assets/nav-orders.svg);
        }
        &.settings::before {
          background-image: url(../../../assets/nav-settings.svg);
        }
        &.cards::before {
          background-image: url(../../../assets/nav-cards.svg);
          width: 22px;
          height: 22px;
        }
        &.p2p::before {
          background-image: url(../../../assets/nav-p2p.svg);
          width: 20px;
          height: 20px;
        }
        &.addresses::before {
          background-image: url(../../../assets/list-tree.svg);
          width: 22px;
          height: 22px;
        }
      }

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a.current {
        cursor: pointer;
        color: $color-white;
        font-weight: bold;
        background: linear-gradient(90deg, #2b1f40 -23.23%, #170d28 100%);
        box-shadow: inset 0px 5px 14px rgba(15, 9, 26, 0.2);
        border-left: 5px solid #6932d4;
      }
    }
  }
}
