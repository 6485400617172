.table-wrapper-assets {
  .illus-wrap {
    display: flex;
    align-items: center;
    justify-content: stretch;

    .illus {
      margin-right: 15px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .info {
      width: 100%;
      line-height: 20px;
    }
  }

  .btns-cell {
    a {
      margin-right: 15px;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100%;
      max-width: 100px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .positive-change,
  .negative-change {
    position: relative;
    padding-left: 15px;
  }

  .positive-change::before,
  .negative-change::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 5px;
    height: 5px;
    display: inline-block;
    border: 5px solid transparent;
  }

  .positive-change::before {
    border-bottom-color: rgb(0, 128, 0);
    margin-top: -2px;
    transform: translate(0, -50%);
  }
  .negative-change::before {
    border-top-color: rgb(255, 23, 68);
    transform: translate(0, -50%);
    margin-top: 3px;
  }

  .positive-change {
    color: rgb(0, 128, 0);
  }
  .negative-change {
    color: rgb(255, 23, 68);
  }
  .no-change {
    color: rgba(0, 0, 0, 0.45);
  }

  .trade-dd {
    padding-left: 15px;

    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  .asset-details {
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .hide-exchange-btn .exchange-btn {
    display: none;
  }

  .detail-activity {
    position: relative;

    .clickable {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 870px) {
      .clickable {
        display: block;
      }
    }
  }

  .detail-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon {
      display: none;
      width: 35px;
    }

    @media (max-width: 768px) {
      .icon {
        display: block;
      }
    }
  }
}
