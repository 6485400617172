@import '../../../styles/base.scss';

.ticker-prices-wrap {
  background-color: #0f091a;
  height: 60px;
  white-space: nowrap;

  .ticker-inner-wrap {
    overflow: auto;
    height: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 25px;
    font-size: 13px;

    li {
      display: inline-block;
      color: $color-white;
      vertical-align: top;
      margin-right: 40px;
      text-transform: uppercase;

      .change {
        display: inline-block;
        vertical-align: top;
        font-size: 11px;

        &.up {
          color: #23fd00;
        }

        &.down {
          color: #fd0000;
        }
      }

      .price {
        font-weight: 600;
      }
    }
  }
}
