@import '../../../styles/base.scss';

.dashboard-header {
  height: $dashboard-header-height;
  padding: 0 25px;
  background-color: $color-white;
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.15);

  .logo {
    display: inline-block !important;

    img {
      height: 40px;
    }
  }

  .row:first-child {
    height: 100%;
  }

  .title {
    color: $color-text-primary;
    font-weight: bold;
  }

  form {
    height: 100%;

    input {
      // background: #fbf9ff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px !important;
      height: 100%;
    }
  }

  .right-content-wrap {
    .handle {
      position: relative;
      cursor: pointer;
      display: block;
      width: 40px;
      height: 40px;
      background: rgba(222, 208, 255, 0.4);
      border: 1px solid rgba(217, 203, 255, 0.4);
      border-radius: 50px;

      &.has-update::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        border: 2px solid #f2f2f2;
        background-color: #333333;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin: -10px 0 0 -10px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .notifications-wrap {
      margin-right: 15px;

      .handle::before {
        background-image: url(../../../assets/icon-notification.svg);
      }
    }

    .user-wrap {
      .handle::before {
        content: '';
        position: absolute;
        top: 10px !important;
        left: unset;
        right: 2px !important;
        width: 12px !important;
        height: 12px !important;
        border-radius: 100%;
        background-color: #f2f2f2;
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/chevron-down.svg);
      }

      .handle::after {
        content: attr(data-initials);
        color: $color-text-primary;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        width: 40px;
        height: 40px;
        line-height: 37px;
        border: 0;

        @media (max-width: 1200px) {
          color: $color-white;
        }
      }

      .dropdown-custom {
        ul {
          list-style: none;
          margin: 0;
          padding: 5px 0;

          a {
            display: block;
            padding: 10px 25px;
            cursor: pointer;
            color: $color-text-primary;
            font-weight: 500;

            &:hover {
              background: rgba(197, 178, 240, 0.5) !important;
            }
          }
        }
      }
    }
  }

  .mobile-menu {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../../assets/menu-bars.svg);

    &.menu-open {
      width: 24px;
      height: 24px;
      background-image: url(../../../assets/menu-close.svg);
    }
  }

  @media (max-width: 1200px) {
    background-color: #0f0919;
  }

  .right-sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0 25px;

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;
      color: rgba(249, 247, 255, 0.7);
      background: rgba(255, 255, 255, 0.1);
      border: 1.5px solid rgba(255, 255, 255, 0.7);
      border-radius: 3px;

      &:hover {
        color: rgba(249, 247, 255, 0.7);
      }

      &.alt {
        background: rgba(240, 178, 178, 0.1);
        border: 1.5px solid rgba(240, 178, 178, 0.7);
      }
    }
  }

  .menu-list ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #6932d4;
      }
    }
  }
}
