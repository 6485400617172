.asset-transfer-preview {
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e9ec;
    padding: 15px 0;

    &:last-child {
      border-bottom: 0;
    }
  }
}
