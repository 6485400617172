.history-table {
  white-space: nowrap;
  overflow: auto;

  table {
    min-width: 880px;

    td {
      cursor: pointer;

      &.is-placeholder {
        padding: 15px;
      }

      .custom-placeholder {
        min-width: 20px;
      }
    }

    @media (max-width: 768px) {
      min-width: 100%;

      th {
        display: none;
      }
    }
  }

  .illus-wrap {
    display: flex;
    align-items: center;
    justify-content: stretch;

    .illus {
      margin-right: 15px;
      margin-top: -5px;

      img {
        width: 20px;
        height: 20px;

        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
        }
      }

      .custom-placeholder {
        width: 20px;
        height: 20px;
      }
    }

    .info {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    background-color: transparent !important;
    box-shadow: none !important;

    td {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .order-details {
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left,
      .right {
        width: 100%;
      }

      .right {
        text-align: right;
      }
    }
  }
}
