.mfa-dialog-content {
  .head {
    display: flex;
    justify-content: stretch;
    align-items: center;

    h4 {
      padding: 0;
      margin: 0;
    }

    .back-button {
      cursor: pointer;
      width: 25px;
      height: 25px;
    }
  }

  .otp-input-container div input {
    text-transform: uppercase;
    border-width: 0;
    background: #f1f1f1;
    border-radius: 12px;

    &:focus,
    &:active {
      border-color: #6932d4 !important;
      border-width: 1px;
    }
  }

  .otp-type-chooser {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: flex;
      justify-content: stretch;
      align-items: center;
      padding: 25px 0;
      border-bottom: 1px solid #e2e1e1;

      &:last-child {
        border-bottom: 0;
      }

      span {
        display: block;

        &.text {
          flex: 1;
          font-weight: 500;
        }

        &.icon {
          width: 35px;
          height: 35px;

          svg {
            width: 35px;
            height: 35px;
          }
        }

        &.arrow {
          display: block;
          width: 16px;
          height: 24px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
